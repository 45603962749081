import { passwordConstants } from './constants';
import { combineReducers } from 'redux';

const initialState = {
  isLoadInner: false,
  isLoad: false,
  message: null,
  loadErr: null,
  data: {},
  success_message: '',
  mfaLoad: false,
  qrLoad: false,
  qrData: {},
};

function changePass(state = initialState, action) {
  switch (action.type) {
    // Loading
    case passwordConstants.LOAD:
      return {
        ...state,
        isLoad: true,
        loadErr: '',
        success_message: '',
      };
    case passwordConstants.LOAD_SUCCESS:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        message: action.message,
        success_message: '',
      };
    case passwordConstants.LOAD_FAIL:
      return {
        ...state,
        isLoad: false,
        loadErr: action.error,
        message: null,
        success_message: '',
      };
    case passwordConstants.MFA_SETTING_LOAD:
      return {
        ...state,
        mfaLoad: true,
        loadErr: '',
        success_message: '',
      };
    case passwordConstants.MFA_SETTING_SUCCESS:
      return {
        ...state,
        mfaLoad: false,
        loadErr: null,
        message: action.message,
        data: action.data,
      };
    case passwordConstants.MFA_SETTING_FAIL:
      return {
        ...state,
        mfaLoad: false,
        loadErr: action.error,
        message: null,
      };
    case passwordConstants.GET_QRCODE_LOAD:
      return {
        ...state,
        qrLoad: true,
        loadErr: '',
        success_message: '',
      };
    case passwordConstants.GET_QRCODE_SUCCESS:
      return {
        ...state,
        qrLoad: false,
        loadErr: null,
        message: action.message,
        qrData: action.data,
      };
    case passwordConstants.GET_QRCODE_FAIL:
      return {
        ...state,
        qrLoad: false,
        loadErr: action.error,
        message: null,
        qrData: {},
      };
    case passwordConstants.SUCCESS:
      return {
        ...state,
        isLoad: false,
        loadErr: null,
        success_message: action.message,
      };
    case passwordConstants.FLUSH_MESSAGES:
      return {
        ...state,
        message: null,
        loadErr: null,
        isLoad: false,
        success_message: '',
      };
    case passwordConstants.QR_FLUSH:
      return {
        ...state,
        message: null,
        loadErr: null,
        isLoad: false,
        success_message: '',
        qrData: {},
        data: {},
      };
    // Default
    default:
      return state;
  }
}
const reducer = combineReducers({
  changePass,
});

export default reducer;
