export const passwordConstants = {
  LOAD: 'password/LOAD',
  LOAD_SUCCESS: 'password/LOAD_SUCCESS',
  LOAD_FAIL: 'password/LOAD_FAIL',
  FLUSH: 'password/FLUSH',
  SAVE_USER: 'password/SAVE_USER',
  ALL_USER: 'password/ALL_USER',
  SUCCESS: 'password/SUCCESS',
  FLUSH_MESSAGES: 'password/FLUSH_MESSAGES',
  MFA_SETTING_LOAD: 'auth/MFA_SETTING_LOAD',
  MFA_SETTING_SUCCESS: 'auth/MFA_SETTING_SUCCESS',
  MFA_SETTING_FAIL: 'auth/MFA_SETTING_FAIL',
  GET_QRCODE_LOAD: 'auth/GET_QRCODE_LOAD',
  GET_QRCODE_SUCCESS: 'auth/GET_QRCODE_SUCCESS',
  GET_QRCODE_FAIL: 'auth/GET_QRCODE_FAIL',
  QR_FLUSH: 'auth/QR_FLUSH',
};
